.hidden {
  display: none;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;

  background-color: white;
  /* padding: 6rem; */
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 100000000;
  position: fixed;
}

@media screen and (max-width: 980px) {
  .modal {
    width: 95%;
  }
}

.childmodal {
  display: flex;
}
.childmodal1 {
  background-color: #007aff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
}
@media screen and (max-width: 500px) {
  .childmodal1 {
    display: none;
  }
}

.childmodal2 {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 70vh;
}

.childmodal2 form {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .childmodal2 {
    width: 100%;
    height: 50vh;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /* backdrop-filter: blur(3px); */
  z-index: 500000;
  position: fixed;
}

.app__dropdown {
  background-color: #f2f8ff;
  /* color: #007aff;
  width: 250px; */
}
/* slider */
